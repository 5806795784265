import React from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'
import {
	FieldRow,
	FieldWrap,
	Label,
	Helper,
	StyledError,
	FieldSet,
	Legend,
	StyledHelp,
	Required,
} from './styles'
import FieldType from './Fields'
const FormFieldSingle = ({
	overrideWidth,
	item,
	errors,
	touched,
	color,
	values,
}) => {
	const { label, inputId, inputName, width, inputRequired, helpText } = item
	if (item && item.inputType === 'checkbox') {
		return (
			<FieldRow>
				<FieldWrap width={overrideWidth ? overrideWidth : width}>
					<FieldSet errors={errors.flag} touched={touched.flag}>
						<Legend>
							{label}
							{inputRequired ? <Required> *</Required> : ''}
						</Legend>
						<FieldType {...{ item, errors, touched, values }} />
					</FieldSet>
					{touched[inputName] && errors[inputName] ? (
						<Helper>{`▲ ` + JSON.stringify(errors[inputName], null, 2)}</Helper>
					) : (
						''
					)}
				</FieldWrap>
			</FieldRow>
		)
	}
	return (
		<FieldRow>
			<FieldWrap width={overrideWidth ? overrideWidth : width}>
				<Label css={color && { color: `${color}` }} htmlFor={inputId}>
					{label}
					{inputRequired ? <Required> *</Required> : ''}
					{/* <StyledError name={inputName} component="span" /> */}
				</Label>
				<FieldType {...{ item, errors, touched, values }} />
				{touched[inputName] && errors[inputName] ? (
					<Helper>{`▲ ` + JSON.stringify(errors[inputName], null, 2)}</Helper>
				) : (
					''
				)}
			</FieldWrap>
		</FieldRow>
	)
}
export default FormFieldSingle

FormFieldSingle.propTypes = {
	item: PropTypes.object,
	errors: PropTypes.object,
	touched: PropTypes.object,
	values: PropTypes.object,
}
