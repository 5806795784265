import * as Yup from 'yup'

/** Flaten form Array items in items */
export const flat = arr => {
	let result = []
	arr.forEach(({ items, ...rest }) => {
		result.push(rest)
		if (Array.isArray(items)) {
			result = result.concat(flat(items))
		}
	})
	/** Filter Values - keep only objects with an inputName */
	return result.filter(item => item.inputName)
}
/** Switch for validation objects */
export const validationSwitch = (key, message, required) => {
	switch (key) {
		case 'firstName':
		case 'givenName':
		case 'lastName':
		case 'familyName':
		case 'name':
		case 'fullName':
		case 'street':
		case 'address1':
		case 'address2':
		case 'city':
		case 'suburb':
		case 'dealerContact':
		case 'dealerName':
		case 'descIssue':
		case 'dolphinSerialNumber' :
			return Yup.string()
				.min(2, 'Too Short!')
				.max(50, 'Too Long!')
				.required(`Required`)
		// .required(`${message ? message : 'Required'}`)
		case 'streetAddress':
		case 'address':
				return Yup.string()
				.min(2, 'Too Short!')
				.max(50, 'Too Long!')
				.required(`Required`)
				.matches(/^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i, 'PO BOX ADDRESS NOT ALLOWED')
				// .matches(/^(?!.*?(\b(?:p(?:ost)?\.?\s*(?:[o0](?:ffice)?)?\.?\s*b(?:[o0]x)?|b[o0]x))|(^[\W]*P[\.\s]*O[\.\s]*\d+[\W]*$)).*$/, 'NO PO BOX')
		case 'state':
		case 'province':
			return Yup.string().required(`Required`)
		// .required(`${message ? message : 'Required'}`)
		case 'email':
		case 'emailAddress':
			return Yup.string()
				.email('Invalid email')
				.required(`Required`)
		// .required(`${message ? message : 'Required'}`)
		case 'phone':
		case 'phoneNumber':
			return Yup.string()
				.min(6, 'Too Short!')
				.max(12, 'Too Long!')
				.required(`Required`)
		// .required(`${message ? message : 'Required'}`)
		case 'mobile':
		case 'mobileNumber':
			return Yup.string()
				.min(10, 'Too Short!')
				.max(12, 'Too Long!')
				.required(`Required`)
		// .required(`${message ? message : 'Required'}`)
		case 'postcode':
		case 'postCode':
			return Yup.string()
				.min(4, 'Too Short!')
				.max(4, 'Too Long!')
				.matches(/^[0-9]{4}/, 'Invalid Postcode')
				.required(`Required`)
		// .required(`${message ? message : 'Required'}`)
		case 'policy':
			return Yup.boolean().required("The Sales & Advertising policy must be accepted.")
				.oneOf([true], "The Sales & Advertising policy must be accepted.")
		case 'readandagree':
			return Yup.boolean().required("The Privacy Policy and Terms & Conditions must be accepted.")
				.oneOf([true], "The Privacy Policy and Terms & Conditions must be accepted.")
		case 'isWarranty':
					return Yup.string().required("Product cannot be serviced by Maytronics AU at this point in time.")
		case 'isRobotBoxed':
			return Yup.string().required("Needs to be boxed up maximum box size is X.")
		default:
			''
			break
	}
}
export const validationSchema = validationItems =>
	Yup.object().shape(validationItems)
