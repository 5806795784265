import React from 'react'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'

import { Lottie } from '@crello/react-lottie'
import * as animationData from '../../animations/checkbox.json'
const StyledCheckbox = tw.div`inline-block w-4 h-4 cursor-pointer bg-white rounded`
const CheckboxContainer = styled.div`
	${tw`flex items-center mr-1`}
	input {
		&[type='checkbox'] {
			${tw`border-0 p-0 h-px w-px -m-px absolute overflow-hidden whitespace-nowrap `}
			clip: rect(0 0 0 0);
			clippath: inset(50%);
		}
	}
`

const Checkbox = ({ className, checked, children, size, ...props }) => {
	return (
		<CheckboxContainer className={className ? className : 'checkbox-container'}>
			{children ? (
				children
			) : (
				<input checked={checked} {...props} type="checkbox" readOnly />
			)}
			<StyledCheckbox
				checked={checked}
				css={{ width: `${size}px`, height: `${size}px` }}
			>
				<Lottie
					playingState={'playing'}
					speed={1}
					direction={checked ? 1 : -1}
					width={size ? size : '16px'}
					height={size ? size : '16px'}
					className="lottie-container checkbox"
					config={{
						animationData: animationData.default,
						loop: false,
						autoplay: true,
						renderer: 'svg',
					}}
				/>
			</StyledCheckbox>
		</CheckboxContainer>
	)
}
Checkbox.propTypes = {
	className: PropTypes.string,
	checked: PropTypes.bool,
}
export default Checkbox
